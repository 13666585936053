import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import Create from './Create';
import { UpdateButton } from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import {
    CustomPagination,
    PersitedDatePicker,
    SearchBar,
    TableCustom,
} from '../../../../components/common';
import useDebounce from '../../../../hooks/useDebounce';
import {
    AddIconButton,
    DeleteIconButton,
    ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import ModalCustom from '../../../../components/common/Modal';
import { useQuery } from '../../../../hooks/UseQuery';
import useConfirm from '../../../../components/common/useConfirm';
import {
    apiConstant,
    checkListList,
    navigationConstant,
} from './helpers/constant';
import formatDate from '../../../../components/common/formatDate';
import CustomUploadButton from '../../../../components/common/button/CustomUploadButton';
import ViewFile from './ViewFile';
import { useNavigate } from 'react-router-dom';
import FilterBy from '../../../../components/common/FilterBy';

const tableHeaderObj = [
    [
        { text: 'S. NO.', rowspan: 1, colspan: 1 },
        { text: 'M/C CODE', rowspan: 1, colspan: 1 },
        { text: 'MACHINE DESC.', rowspan: 1, colspan: 1 },
        { text: 'DUE DATE', rowspan: 1, colspan: 1 },
        { text: 'LAST DATE', rowspan: 1, colspan: 1 },
        { text: 'STATUS', rowspan: 1, colspan: 1 },
        { text: 'ATTACH PROOF', rowspan: 1, colspan: 1 },
        { text: 'CHECKLIST', rowspan: 1, colspan: 1 },
        { text: 'ACTION', rowspan: 1, colspan: 1 },
    ],
];

const filterOptions = [
    { value: 'createdAt', name: 'A-Z' },
    { value: 'customer_name', name: 'Customer Name' },
];

const getCurrentStatus = (status: any) => {
    const statusMap: any = {
        overdue: [{ bgColor: '#e03131', width: '300px', text: 'OVERDUE' }],
        pending: [{ bgColor: '#fcc419', width: '300px', text: 'NOT STARTED' }],
        started: [{ bgColor: '#5c7cfa', width: '300px', text: 'ONGOING' }],
        completed: [{ bgColor: '#2f9e44', width: '300px', text: 'COMPLETED' }],
    };

    const [{ bgColor, text, width }] = statusMap[status] || [];
    return bgColor ? (
        <TableCell align="center" style={{ backgroundColor: bgColor, width }}>
            {text}
        </TableCell>
    ) : null;
};

const List = () => {
    const [data, setData] = useState<any[]>([]);
    const [render, setRender] = useState(false);
    const [perPage, setPerPage] = useState<number>(10);
    const [totalPages, setTotalPages] = useState(0);
    const search = useQuery('search') || '';
    const date = useQuery('date');
    const idRef = useRef<any>({});
    const [isOpenFiles, setIsOpenFiles] = useState(false);
    const page = useQuery('page') || 1;
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [DialogDelete, confirmDelete] = useConfirm(
        'DELETE',
        'Are you sure you want to delete this?',
    );
    const [listId, setListId] = useState('');
    const [docFiles, setDocFiles] = useState<any>({});
    const [filterData, setFilterData] = useState([]);
    const machine = useQuery('machine');

    useEffect(() => {
        axios
            .get(`/api/${apiConstant.forAutoComplete}`)
            .then((res) => {
                if (res.data) {
                    const formattedData = res.data.map((item: any) => ({
                        name: item.machine_no,
                        value: item._id,
                    }));
                    setFilterData(formattedData);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const fetchData = async () => {
        const res = await axios
            .get(
                `/api/${apiConstant.getAllStatus}/${date}/?page=${page}&search=${search}&perPage=${perPage}&filterBy=${machine}`,
            )
            .catch((err) => {
                console.log(err);
            });
        setData(res?.data?.results);
        setPerPage(res?.data?.perPage);
        setTotalPages(res?.data?.pages);
    };

    const handleDelete = async (item: any) => {
        const ans = await confirmDelete();
        if (!ans) return;
        await axios
            .put(
                `/api/${apiConstant.upadateListStatus}/${item.docId}/${item.month}?checkListId=${item?.checklist_id}`,
                {
                    p: '',
                    isTask: false,
                    doneDate: null,
                    created_date: null,
                    checklist_id: null,
                    status: 'pending',
                },
            )
            .then((res) => {
                alert('Document deleted successfully');
                setRender((prev) => !prev);
            })
            .catch((err) => console.log(err));
    };

    useDebounce(fetchData, 500, [
        page,
        search,
        date,
        isOpenPopup,
        render,
        isOpenFiles,
        machine,
    ]);

    const uploadFile = async (e: any, item: any) => {
        if (!window.confirm('Are you sure you want upload this Files')) return;
        const files = e.target.files;
        if (!files) return;
        let formData = new FormData();
        for (const key of Object.keys(files)) {
            formData.append('files', files[key]);
        }
        setIsLoading(true);

        axios
            .patch(
                `/api/${apiConstant.checklistUpload}/${item.docId}/${item.month}`,
                formData,
            )
            .then((res) => {
                if (res.data) {
                    alert('document upload successly');
                    setIsLoading(false);
                    setRender((prev) => !prev);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    const handleNavigate = useCallback(
        (item: any, isView: true | false) => {
            navigate(
                `${navigationConstant.checklistRouter}/${item?.checklist_id}?planId=${item?.plan_id}&documentId=${item?.docId}&month=${item?.month}&date=${date}&isView=${isView}&machineName=${item.a1}&machineNo=${item.a2}`,
            );
        },
        [navigate],
    );

    return (
        <>
            <ChildHeader text={`${checkListList.childHeader}`}></ChildHeader>

            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                sx={{
                    justifyItems: 'center',
                    alignItems: 'center',
                    margin: '15px',
                    width: '100%',
                    gap: 2,
                }}
            >
                <SearchBar label="Search" sx={{ width: { xs: '100%', sm: '67%' } }} />
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ gap: 2 }}>
                    <FilterBy
                        sx={{ minWidth: '190px', maxWidth: '190px' }}
                        name="machine"
                        label="Filter By Machine"
                        options={filterData}
                        variant={'outlined'}
                    />
                    <PersitedDatePicker
                        label={`${checkListList.datePickerLabel}`}
                        views={['year']}
                    />
                </Stack>
            </Stack>

            <TableCustom tableHeaderObj={tableHeaderObj}>
                <TableBody>
                    {data?.length > 0 &&
                        data.map((item: any, index: number) => (
                            <React.Fragment key={index + 'status_row'}>
                                <TableRow>
                                    <TableCell align="center">
                                        {perPage * (+page - 1) + index + 1}
                                    </TableCell>
                                    <TableCell align="center">{item?.a1}</TableCell>
                                    <TableCell align="center">{item?.a2}</TableCell>
                                    <TableCell align="center">{item?.dueDate}</TableCell>
                                    <TableCell align="center">
                                        {formatDate(item.doneDate)}
                                    </TableCell>
                                    {getCurrentStatus(item?.status)}
                                    <TableCell align="center">
                                        <Stack direction="row" sx={{ justifyContent: 'center' }}>
                                            <CustomUploadButton
                                                accept="image/png,image/jpeg,image/jpg,application/pdf"
                                                id={`${index}_files_`}
                                                multiple={true}
                                                onChange={(e) => {
                                                    uploadFile(e, item);
                                                }}
                                            />
                                            {item.files?.length > 0 ? (
                                                <>
                                                    <ViewIconButton
                                                        tooltipTitle="view"
                                                        onClick={() => {
                                                            idRef.current = {
                                                                docId: item.docId,
                                                                month: item.month,
                                                            };
                                                            setDocFiles(item.files);
                                                            setIsOpenFiles(true);
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <ViewIconButton
                                                    sx={{
                                                        opacity: '0',
                                                        PointerEvent: 'none',
                                                        visibility: 'hidden',
                                                    }}
                                                    tooltipTitle=""
                                                />
                                            )}
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Stack
                                            direction="row"
                                            sx={{ justifyContent: 'center', alignItems: 'center' }}
                                        >
                                            {item.checklist_id ? (
                                                <ViewIconButton
                                                    tooltipTitle="Modify Checklist"
                                                    onClick={() => {
                                                        handleNavigate(item, true);
                                                    }}
                                                />
                                            ) : (
                                                <AddIconButton
                                                    tooltipTitle="Edit Checklist"
                                                    onClick={() => {
                                                        handleNavigate(item, false);
                                                    }}
                                                />
                                            )}

                                            {item.checklist_id ? (
                                                <div
                                                    style={{
                                                        background: '#51cf66',
                                                        borderRadius: '50%',
                                                        width: '20px',
                                                        height: '20px',
                                                    }}
                                                ></div>
                                            ) : (
                                                <div
                                                    style={{
                                                        opacity: '0',
                                                        borderRadius: '50%',
                                                        width: '20px',
                                                        height: '20px',
                                                    }}
                                                ></div>
                                            )}
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" sx={{ justifyContent: 'center' }}>
                                            <DeleteIconButton
                                                tooltipTitle="DELETE"
                                                onClick={() => {
                                                    handleDelete(item);
                                                }}
                                            />
                                            <UpdateButton
                                                label="update status"
                                                sx={{ margin: '10px' }}
                                                size="small"
                                                onClick={() => {
                                                    setListId(item);
                                                    setIsOpenPopup(true);
                                                }}
                                            />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                </TableBody>
            </TableCustom>
            <ModalCustom
                title={`${checkListList.modalTile}`}
                openModal={isOpenPopup}
                closeModal={() => {
                    setIsOpenPopup(false);
                    setListId('');
                }}
            >
                <Create setIsModal={setIsOpenPopup} item={listId} />
            </ModalCustom>
            <ModalCustom
                title="FILE LIST"
                openModal={isOpenFiles}
                closeModal={() => {
                    setIsOpenFiles(false);
                }}
            >
                <ViewFile
                    id={idRef.current}
                    docFiles={docFiles}
                    setIsOpenFiles={setIsOpenFiles}
                />
            </ModalCustom>
            <CustomPagination totalPage={totalPages} />
            <DialogDelete isSubmitContent={false} />
        </>
    );
};

export default List;
