import { BsFillPeopleFill } from 'react-icons/bs';
import { CgUserList } from 'react-icons/cg';
import { FcDepartment } from 'react-icons/fc';
import { ImUserTie } from 'react-icons/im';
import { MdOutlineDomainAdd } from 'react-icons/md';
import { GrUserWorker, GrVmMaintenance } from 'react-icons/gr';

const tiles = [
  {
    sNo: 1,
    link: '/admin/masterlistofdepartment?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    p: 'Master List Of',
    span: 'Department',
    icon: <MdOutlineDomainAdd size="5rem" color="#343a40" />,
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/admin/employee_list?filter=createdAt',
    isLink: true,
    p: 'MASTER LIST OF',
    span: 'EMPLOYEE',
    isIcon: true,
    icon: <ImUserTie size="5rem" color="#1864ab" />,
  },
  {
    sNo: 1,
    link: '/common/jobDescHome',
    isLink: true,
    clauseNo: 5,
    icon: <CgUserList size="5rem" color="#135089" />,
    p: 'JOB',
    span: 'DESCRIPTION',
    isIcon: true,
  },
  // {
  //   sNo: 1,
  //   link: '/common/employeeList',
  //   isLink: true,
  //   clauseNo: 5,
  //   icon: <BsFillPeopleFill size="5rem" color="#a12222" />,
  //   p: 'MASTER LIST OF',
  //   span: 'EMPLOYEE (Staff)',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/departmentList',
  //   isLink: true,
  //   clauseNo: 5,
  //   p: 'MASTER LIST OF',
  //   span: 'DEPARTMENT',
  //   icon: <FcDepartment size="5rem" color="#135089" />,
  //   isIcon: true,
  // },
  {
    sNo: 2,
    link: '/common/productionWorkersList?filter=createdAt',
    isLink: true,
    icon: <GrUserWorker size="5rem" color="#a12222" />,
    p: 'Master List of',
    span: 'Workers',
    isIcon: true,
  },
];

export default tiles;
