import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={13}
        rowSpan={4}
        labels={[
          'FORMAT NO: ',
          'ISSUE DATE:',
          'REVISION NO:',
          'REVISION DATE:',
        ]}
        values={['F QAD 36', '01/01/2020', '01', '15/09/2021']}
        heading="INSTRUMENT & EQUIPMENT HISTORY CARD"
      />

      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S.NO',
          'DESCRIPTION',
          'ID NO.',
          'MAKE',
          'SERIAL NO.',
          'RANGE',
          'LEAST COUNT',
          'CALIBRATED ON',
          'DUE DATE OF CAL.',
          'RE-CAL. DATE (IF ANY)',
          'REPAIR DATE (IF ANY)',
          'OBSOLETE DATE (IF ANY)',
        ].map((head) => (
          <th
            style={{ textTransform: 'uppercase' }}
            colSpan={head === 'OBSOLETE DATE (IF ANY)' ? 2 : 1}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr></tr>
    </thead>
  );
};

export default THead;
