import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../../components/ui/ChildHeader';
import {
  CustomButton,
  UpdateButton,
} from '../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../components/common/useConfirm';
import TController from './components/TController';
import { Stack } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import Edit from '@mui/icons-material/Edit';

const CreateMachineHistory = () => {
  const [moduleData, setModuleData] = useState<any>({
    histories: [],
  });
  const [render, setRender] = useState(false);
  const { id } = useParams();
  const [isView, setIsView] = useState(true);
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterlistinstrument/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;

    moduleData.calibration_done_date =
      moduleData?.histories?.at(-1)?.calibration_done_date || null;

    await axios
      .put(`/api/masterlistinstrument/${id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('Updated Successfully');
        }
      })
      .catch((err) => console.log(err));
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.histories.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text="INSTRUMENT & EQUIPMENT HISTORY CARD">
          <Stack gap={2} direction="row">
            <CustomButton
              sx={{
                backgroundColor: '#1c7ed6',
              }}
              icon={isView ? <Edit /> : <Visibility />}
              onClick={() => {
                setIsView((prev) => !prev);
              }}
            >
              {isView ? 'EDIT' : 'VIEW'}
            </CustomButton>
            {!isView && (
              <>
                <CustomButton
                  sx={{
                    backgroundColor: '#1c7ed6',
                  }}
                  onClick={async () => {
                    const ans = await confirmAddRow();
                    if (!ans) return;
                    moduleData.histories.push({});
                    setRender((prev) => !prev);
                  }}
                >
                  ADD ROWS
                </CustomButton>
                <UpdateButton label="Update" onClick={() => handleSumbit()} />
              </>
            )}
          </Stack>
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handelDeleteRows,
        }}
      />
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
    </>
  );
};

export default CreateMachineHistory;
