import { Clause5 } from '../../pages/clause5page';
import List from '../../pages/clause5page/employee/List';
// import StaffList from '../../pages/clause5page/employeeStaff/List';
import MasterlistOfDepartment from '../../pages/clause5page/department/List';

export const clause5 = [
  {
    path: 'clause5',
    element: <Clause5 />,
  },
  {
    path: 'employee_list',
    element: <List />,
  },
  // {
  //   path: 'employeeStaff_list',
  //   element: <StaffList />,
  // },
  {
    path: 'masterlistofdepartment',
    element: <MasterlistOfDepartment />,
  },
];
