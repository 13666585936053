import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';

import { IData } from './Interface';
import Profile from './components/Profile';
import formatDate from '../../../components/common/formatDate';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'NAME', rowspan: 1, colspan: 1 },
    { text: 'EMPLOYEE Code', rowspan: 1, colspan: 1 },
    { text: 'DEPARTMENT', rowspan: 1, colspan: 1 },
    { text: 'DESIGNATION', rowspan: 1, colspan: 1 },
    { text: 'Level', rowspan: 1, colspan: 1 },
    { text: 'Qualification', rowspan: 1, colspan: 1 },
    { text: 'Total Experience', rowspan: 1, colspan: 1 },
    { text: 'DOJ', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const designationFilterOptions = [
  { value: 'inspector', name: 'Inspector' },
  { value: 'operator', name: 'Operator' },
  { value: 'lineLeader', name: 'Line Leader' },
  { value: 'staff', name: 'Staff' },
];

const filterOptions = [{ value: 'createdAt', name: 'Most Recent First' }];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [moduleId, setModuleId] = useState<string | null>(null);
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [profile, setProfile] = useState('');

  const search = useQuery('search');
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const designationFilter = useQuery('designationFilter');

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE Employee',
    'Are you sure you want to delete this?'
  );
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/employee?page=${page}&designationFilter=${designationFilter}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteModuleById = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/employee/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    isModal,
    designationFilter,
    filter,
  ]);

  return (
    <>
      <ChildHeader text="EMPLOYEE LIST">
        <AddButton onClick={() => setIsModal(true)} label="New Employee" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />

        <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ gap: 2 }}>
          <FilterBy
            name="designationFilter"
            label="Filter By Designation"
            options={designationFilterOptions}
          />
          <FilterBy options={filterOptions} />
        </Stack>
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.employee_name}</TableCell>
                  <TableCell align="center">{item?.employee_code}</TableCell>
                  <TableCell align="center">
                    {typeof item?.department_id === 'object' &&
                      item?.department_id?.department_name}
                  </TableCell>
                  <TableCell align="center">{item?.designation}</TableCell>
                  <TableCell align="center">{item?.level}</TableCell>
                  <TableCell align="center">{item?.qualification}</TableCell>
                  <TableCell align="center">{item?.exp_years}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.date_of_joining)}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <ViewIconButton
                        tooltipTitle="VIEW PROFILE"
                        onClick={() => {
                          setIsProfileModal(true);
                          setProfile(item?.profile);
                        }}
                      />
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setModuleId(item?._id || '');
                          setIsModal(true);
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteModuleById(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setModuleId(null);
          setIsModal(false);
        }}
        title="EMPLOYEE"
      >
        <Create id={moduleId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        openModal={isProfileModal}
        closeModal={() => {
          setProfile('');
          setIsProfileModal(false);
        }}
        title="PROFILE"
      >
        <Profile profileUrl={profile} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
